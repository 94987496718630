




















































import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"

import CareerApi from "@/api/Career/CareerApi"
import BreakpointMixin from "@/types/BreakpointMixin"
import Career from "@/types/Career/Career"
import CareerDetail from "@/types/Career/CareerDetail"
import InputMixin from "@/types/InputMixin"

/** Components */
import BackBar from "@/components/BackBar.vue"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
import ViewWrapper from "@/views/ViewWrapper.vue"
import ModalPolicy from "@/components/Policy/ModalPolicy.vue"

@Component({
  name: "CareerDetailPage",
  components: { BackBar, SectionUnderLine, ViewWrapper, ModalPolicy }
})
export default class CareerDetailPage extends mixins(InputMixin, BreakpointMixin) {
  private detail: CareerDetail = new CareerDetail()

  private selectedPosition = -1

  private careerList: Career[] = []

  private isActive: boolean = false

  mounted() {
    const id = ((this.$route.query.id as unknown) as number) || -1
    if (id === -1) {
      this.$router.push({ name: "CareerMainPage" })
    }
    this.fetchCareerDetail(id)
    CareerApi.listCareers().then(({ data }) => {
      this.careerList = _.map(data, (d) => deserialize<Career>(d, Career))
    })
  }

  @Watch("selectedPosition")
  onSelectedPositionChange() {
    if (this.selectedPosition !== -1) {
      this.fetchCareerDetail(this.selectedPosition)
    }
  }

  fetchCareerDetail(id: number) {
    CareerApi.getCareerDetail(id).then(({ data }) => {
      this.detail = deserialize<CareerDetail>(data, CareerDetail)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.selectedPosition = this.detail.id!
    })
  }

  toDetailMainPage() {
    this.$router.push({ name: "CareerMainPage" })
  }

  toCareerApplyPage() {
    this.$router.push({ name: "CareerApplyPage", query: { id: `${this.selectedPosition}` } })
  }

  private generateRoute() {
    return { name: "CareerApplyPage", query: { id: `${this.selectedPosition}` } }
  }

  private openModalPolicy() {
    this.isActive = true
  }

  private accept() {
    this.$router.push(this.generateRoute())
  }
}
